<template>
  <div
    @contextmenu.prevent="$refs.courseSectionViewEditMenu.open"
    :style="`${calculateBorderColor(courseColor)} padding: 0.075rem`"
    class="rounded position-relative overflow-hidden drop-shadow-default"
  >
    <div class="rounded" style="background: #191a22">
      <div
        class="card-body rounded z-2 position-relative d-flex align-items-center justify-content-between"
        :style="calculateBackgroundColor(courseColor)"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            :src="courseData.avatar"
            :text="avatarText(courseData.name)"
            size="80px"
            variant="light-info"
            style="margin-top: 1px"
            class="mr-1"
            rounded
          />
          <div class="d-flex flex-column align-items-start justify-content-center">
            <b-link
              :to="{ name: 'apps-roster-course-view', params: { id: courseData.id } }"
              class="d-flex align-items-center text-white font-weight-light"
            >
              <small class="" style="font-size: 1.15rem; line-height: 1.35rem; margin-bottom: 1px">{{
                courseData ? courseData.name : courseData.name
              }}</small>
            </b-link>

            <div class="d-flex align-items-center mb-25">
              <h4
                class="font-weight-bolder tracking-tighter mr-50 cursor-pointer"
                style="font-size: 1.85rem; line-height: 2.15rem; margin-bottom: 2px"
                @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: cohortSummary.cohort.id } })"
              >
                {{ resolveCourseSectionName(cohortSummary.cohort).replace('Section', 'Cohort') }}
              </h4>
            </div>

            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <b-badge class="profile-badge mr-25" variant="primary">
                  {{ title(cohortSummary.cohort.status) }}
                </b-badge>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <b-button
            :to="{ name: 'apps-accounting-receivable-cohort-edit', params: { id: cohortSummary.cohort.id } }"
            variant="primary"
            size="sm"
            block
          >
            Edit Cohort
          </b-button>

          <b-button @click="() => initiateDownload()" variant="secondary" size="sm" block> Export </b-button>

          <b-button @click="() => initiateReportsQueue()" variant="info" size="sm" block> Queue Reports </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge, BLink } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useCohortsList from '../list/useCohortsList';
import { title } from '@core/utils/filter';
import useAppConfig from '@core/app-config/useAppConfig';
import moment from 'moment';
import fileDownload from 'js-file-download';
import router from '@/router';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import { hexToRGB } from '@/libs/utils/color';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const { skin } = useAppConfig();

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BLink,
    ToastificationContent,
  },
  props: {
    cohortSummary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    courseData() {
      const cohort = this.cohortSummary.session;
      if (!cohort) return { metadata: { design: {} } };
      return cohort.type === 'course' ? cohort.course : cohort.pathway;
    },
    courseColor() {
      const data = this.courseData;
      if (data && data.metadata && data.metadata.design && data.metadata.design.color) {
        return data.metadata.design.color;
      }
      return '#5b5b5e';
    },
    pathwayColor() {
      const data = this.courseData;
      if (data && data.metadata && data.metadata.design && data.metadata.design.color) {
        return data.metadata.design.color;
      }
      return '#5b5b5e';
    },
    pathwayImage() {
      const data = this.courseData;
      if (data && data.metadata && data.metadata.design && data.metadata.design.image) {
        return data.metadata.design.image;
      }
      return null;
    },
  },
  methods: {
    initiateReportsQueue() {
      const params = { cohort: this.cohortSummary.id };

      this.$store
        .dispatch('receivableStoreModule/exportCohortFinancialReports', { id: this.cohortSummary.cohort.id })
        .then((response) => {
          const { data } = response;
          const { job } = data;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Job queued successfully',
              variant: 'success',
              text: 'Successfully created a job to create the financial reports.',
            },
          });

          this.$router.push({ name: 'apps-job-list', query: { jobId: job.id, refresh: '10s' } });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to queue financial reports.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });

          console.error(`failed to download ar cohort export`, error);
        });
    },
    calculateBackgroundColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.35) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05) 93.34%)`;
    },
    calculateBorderColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(190deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.03) 100%);`;
    },
    copyCohortPassword() {
      console.log(`copying cohort password`);
      this.$copyText(`https://recruiters.intellectualpoint.com/cohorts/${this.cohortSummary.id}?code=${btoa(this.cohortSummary.password)}`)
        .then((a) => {
          console.log(`copied`, a);
        })
        .catch((e) => console.error(e));
    },
    initiateDownload() {
      const params = { cohort: this.cohortSummary.id, ssn: false };

      this.$store
        .dispatch('receivableStoreModule/exportCohortXlsx', {
          id: this.cohortSummary.cohort.id,
          queryParams: params,
        })
        .then((response) => {
          const { data } = response;
          fileDownload(
            data,
            `${this.cohortSummary.cohort.name} Report.xlsx`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          );
        })
        .catch((error) => {
          console.error(`failed to download ar cohort export`, error);
        });
    },
  },
  setup() {
    const { resolveCohortStatusVariant } = useCohortsList();
    const { resolveCourseSectionName } = useCourseSections();
    const { resolvePathwayCohortName } = usePathwayCohorts();
    return {
      avatarText,
      resolveCohortStatusVariant,
      title,
      skin,
      moment,

      resolveCourseSectionName,
      resolvePathwayCohortName,
    };
  },
};
</script>

<style scoped lang="scss">
$card-spacer-y: 0.75rem !default;

.card-account {
  .card-body {
    position: relative;
    padding: 1.5rem 1rem $card-spacer-y 1rem !important;
  }
}

.price-details {
  .price-title {
    font-weight: 600 !important;
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
  }

  .price-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .detail-title {
      &.detail-total {
        font-weight: bolder !important;
      }
    }
  }
}
</style>
